import { useEffect, useState,useRef } from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import * as ProductsServices from '../../services/ProductsServices';
import * as UsersServices from '../../services/UsersServices';
import * as InvoicesServices from '../../services/InvoicesServices';
import * as CustomersServices from '../../services/CustomersServices';
import * as StocksServices from '../../services/StocksServices';
import NavBar from '../../components/NavBar';
import HeaderSection from '../../components/HeaderSection';
import Search from '../../components/Search';
import Filters from '../../components/Filters';
import Pagination from '../../components/Pagination';
import LinearProgress from '@mui/joy/LinearProgress';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import Input from '@mui/joy/Input';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import Typography from '@mui/joy/Typography';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import FormLabel from '@mui/joy/FormLabel';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import AspectRatio from '@mui/joy/AspectRatio';
import CardOverflow from '@mui/joy/CardOverflow';
import Grid from '@mui/joy/Grid';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import IconButton from '@mui/joy/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import Drawer from '@mui/joy/Drawer';
import SearchIcon from '@mui/icons-material/Search';
import { setConsent } from 'firebase/analytics';
import { Navigate, useNavigate } from "react-router-dom";
import InvoiceTable from '../../components/InvoiceTable';
import InvoiceList from '../../components/InvoiceList';
import ModalOverflow from '@mui/joy/ModalOverflow';
import { Chip } from '@mui/joy';
import { calculateTotalDelivery, calculateTotalDuty } from '../../utils/PriceUtils';

var filteredCountry = "GLOBAL";
var filteredPrice = [0, 15000];

export default function InvoicesDashboard() {

  const navigate = useNavigate();

  const [accessToken, setAccessToken]= useState(localStorage.getItem("accessToken"));
  const [username, setUsername]= useState(localStorage.getItem("name"));
  const [email, setEmail]= useState(localStorage.getItem("email"));
  const [role, setRole] = useState(localStorage.getItem("role"));
  const [uid, setUid] = useState(localStorage.getItem("uid"));
  const [productsData, setProductsData] = useState([]);
  const [fullProductsData, setFullProductsData] = useState([]);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  const [rowModesModel, setRowModesModel] = useState({});
  const [open, setOpen] = useState(false);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [, forceRender] = useState(undefined);
  const [selectedProduct, setSelectedProduct] = useState(false);
  const [prevCart, setPrevCart] = useState(null);
  const [totalprice, setTotalprice] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [invoicesData, setInvoicesData] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [distributors, setDistributors] = useState([]);
  const [salespeople, setSalespeople] = useState([]);

  function handleSelectedRow(data){
    let bigarray = invoicesData.filter((invoice)=> invoice?.id.includes(data) );
    setSelectedRow(bigarray[0]);
    setPaymentModalOpen(true);
  }

  const toggleDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen);
  };

  const inputRef = useRef(null);
  
  // const [searchVal, setSearchVal] = useState("");

  var searchVal = "";

  var ordertype = "instock";

  var paymenttype = "cash";

  var customerId = "";

  var customerEmail = "";

  var selectedDistributor = "";

  const handleOnChange = (event) => {
    ordertype=event.target.id;
  }

  const handleOnDistributorChange = (event) => {
    selectedDistributor=event.target.id;
  }

  const handleOnCustomerIdChange = (event) => {
    customerId="GRC-" + event.target.value;
  }

  const handleOnCustomerEmailChange = (event) => {
    customerEmail=event.target.value;
  }

  const handleStockUpdate = (row, item) => {
    const data = {
      id: row.id,
      cashierid: row.cashierid,
      stockupdate:{
        item: item.id,
        quantity: item.quantity
      },
      product:item
    };
    update_stock(JSON.stringify(data, null, 2));
    setPaymentModalOpen(false);
  }

  const handleStockDeduct = (row, item) => {
    const data = {
      id: row.id,
      distributorid: selectedDistributor,
      stockdeduct:{
        item: item.id,
        quantity: item.quantity
      },
      product:item
    };
    deduct_stock(JSON.stringify(data, null, 2));
    setPaymentModalOpen(false);
  }

  // API CALL
  const update_sales = async (invoiceid, selectedSales) => {
    try {
      setLoading(true);
      const data = {
        sales:selectedSales,
        id: invoiceid
      }
      let res = await InvoicesServices.update_salesperson(data);
      if(res.data.success == true){
        read_all_invoices();
        alert("You have successfully updated the sales")
       }
      setLoading(false);
    } catch (e) {
      alert(e);
    }
  };


  // API CALL
  const deduct_stock = async (details) => {
    try {
      setLoading(true);
      let res = await StocksServices.deduct_stock(details);
      if(res.data.success == true){
        read_all_invoices();
        alert("You have successfully updated the stock")
       }
      setLoading(false);
    } catch (e) {
      alert(e);
    }
  };

  // API CALL
  const update_stock = async (details) => {
    try {
      setLoading(true);
      let res = await StocksServices.update_stock(details);
      if(res.data.success == true){
        read_all_invoices();
        alert("You have successfully updated the stock")
       }
      setLoading(false);
    } catch (e) {
      alert(e);
    }
  };

  // API CALL
  const add_one_invoice = async (details) => {
    try {
      setLoading(true);
      let res = await InvoicesServices.add_one_invoice(details);
      if(res.data.success == true){
        fetch_user_cart();
        alert("You have successfully created an invoice")
       }
      setLoading(false);
    } catch (e) {

    }
  };

  // API CALL
  const read_all_invoices = async () => {
    try {
      setLoading(true);
      if (role == "sales" || role == "distributor"){
        let res = await InvoicesServices.get_all_invoices_by_cashier_id(uid);
        setInvoicesData(res.data);
      }else if(role == "superadmin"  || role == "admin" ){
        let res = await InvoicesServices.get_all_invoices();
        setInvoicesData(res.data);
      }else{
        setInvoicesData([]);
      }
      console.log(invoicesData)
      setLoading(false);
    } catch (e) {

    }
  };

  // API CALL
  const get_all_distributors = async () => {
    try {
      let data = {"role":["superadmin","admin","distributor"]}
      let res = await UsersServices.get_users_by_role(data);
      setDistributors(res.data.users);
    } catch (e) {
      console.log(e);
    }
  };

  // API CALL
  const get_all_sales = async () => {
    try {
      let data = {"role":["superadmin","admin","distributor","sales"]}
      let res = await UsersServices.get_users_by_role(data);
      setSalespeople(res.data.users);
    } catch (e) {
      console.log(e);
    }
  };
  

  //API
  const fetch_products = async () => {
    try {
      setLoading(true);
      let res = await ProductsServices.get_all_products();
      setProductsData(res.data);
      setFullProductsData(res.data);
      setLoading(false);
    } catch (e) {
      setError(true);
    }
  };

   //API
   const fetch_product_by_id = async (id) => {
    try {
      setLoading(true);
      let res = await ProductsServices.get_product_by_id(id);
      setProductsData(res.data);
      setFullProductsData(res.data);
      setLoading(false);
    } catch (e) {
      setError(true);
    }
  };

  //API
  const update_cart_item = async (cartitem) => {
    try {
      let res = await UsersServices.update_user_product(cartitem);
      if(res.data.success == true){
        fetch_user_cart();
        alert("You have successfully added to cart")
       }
    } catch (e) {
      setError(true);
    }
  };

   //API
   const delete_cart_items = async (cartitem) => {
    try {
      let res = await UsersServices.delete_all_user_product(uid,cartitem);
      if(res.data.success == true){
        fetch_user_cart();
        alert("You have successfully deleted cart item")
       }
    } catch (e) {
      setError(true);
    }
  };

  //API
  const fetch_user_cart = async () => {
    try {
      setLoading(true);
      let res = await UsersServices.get_one_user(uid);
      setPrevCart(res.data.cart);
      let total = 0;
      res.data.cart != null && res.data.cart != [] && res.data.cart.length > 0 ? res.data.cart.map ((product) =>
        total += product?.quantity * product?.productdetails?.retailprice) : total = 0;  
      setTotalprice(total);
      setLoading(false);
    } catch (e) {
      setError(true);
    }
  };

  //API
  const fetch_customer_by_id = async (id) => {
    try {
      setLoading(true);
      let res = await CustomersServices.get_one_customer(id);
      setCustomerData(res.data);
      setLoading(false);
    } catch (e) {
      setError(true);
    }
  };

  //API
  const fetch_customer_by_email = async (email) => {
    try {
      setLoading(true);
      let res = await CustomersServices.get_one_customer_by_email(email);
      setCustomerData(res.data);
      setLoading(false);
    } catch (e) {
      setError(true);
    }
  };

  function handleSearchCustomerById(){
    fetch_customer_by_id(customerId)
  }

  function handleSearchCustomerByEmail(){
    fetch_customer_by_email(customerEmail)
  }

  function handleDeleteCartItem(){
    const data = {
      id: uid
    }
    delete_cart_items(JSON.stringify(data, null, 2));
  }

  function handlePriceChange(data){
    filteredPrice=data;
  }

  async function handleCountryChange(data){
    filteredCountry = data;
  }

  function handleCardClick(data){
    const product = fullProductsData.find(product => product.id === data);
    setSelectedProduct(product);
    setOpen(true);
  }

  function handlePaymentButtonClick(){
    setPaymentModalOpen(true);
  }



  function handleOrderBy(data) {
    var sortedData = productsData;
    switch(data){

      case"lowestPriceFirst":
        sortedData = productsData.sort((a,b) => {
          return a.retailprice - b.retailprice;
        });
        break;
        
      case"highestPriceFirst":
        sortedData = productsData.sort((a,b) => {
          return b.retailprice - a.retailprice;
        });
        break;
      
      case"highestStockFirst":
        sortedData = productsData.sort((a,b) => {
          return b.currentstock - a.currentstock;
        });
        break;
      
      case"lowestCommissionFirst":
        sortedData = productsData.sort((a,b) => {
          return (((a.retailprice - a.variantcompareprice * 5) / (a.variantcompareprice * 5) * 100)>=60?
          (a.retailprice*20/100).toFixed(2):(a.retailprice*10/100).toFixed(2)) -
          (((b.retailprice - b.variantcompareprice * 5) / (b.variantcompareprice * 5) * 100)>=60?
          (b.retailprice*20/100).toFixed(2):(b.retailprice*10/100).toFixed(2))
        });
        break;

      case"highestCommissionFirst":
        sortedData = productsData.sort((a,b) => {
          return (((b.retailprice - b.variantcompareprice * 5) / (b.variantcompareprice * 5) * 100)>=60?
          (b.retailprice*20/100).toFixed(2):(b.retailprice*10/100).toFixed(2)) -
          (((a.retailprice - a.variantcompareprice * 5) / (a.variantcompareprice * 5) * 100)>=60?
          (a.retailprice*20/100).toFixed(2):(a.retailprice*10/100).toFixed(2))
        });
        break;

      case"lowestIdFirst":
        sortedData = productsData.sort((a,b) => {
          const idA = a.id.toUpperCase(); // ignore upper and lowercase
          const idB = b.id.toUpperCase(); // ignore upper and lowercase
          if (idA < idB) {
            return -1;
          }
          if (idA > idB) {
            return 1;
          }

          // ids must be equal
          return 0;
        });
        break;

      case"highestIdFirst":
      sortedData = productsData.sort((a,b) => {
        const idA = a.id.toUpperCase(); // ignore upper and lowercase
        const idB = b.id.toUpperCase(); // ignore upper and lowercase
        if (idA > idB) {
          return -1;
        }
        if (idA < idB) {
          return 1;
        }

        // ids must be equal
        return 0;
      });
      break;  

      case"ascendingName":
      sortedData = productsData.sort((a,b) => {
        const nameA = a.productname.toUpperCase(); // ignore upper and lowercase
        const nameB = b.productname.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;  
      });
      break; 

      case"descendingName":
      sortedData = productsData.sort((a,b) => {
        const nameA = a.productname.toUpperCase(); // ignore upper and lowercase
        const nameB = b.productname.toUpperCase(); // ignore upper and lowercase
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }

        // names must be equal
        return 0;  
      });
      break; 
    }
  
    setProductsData(sortedData);
    // ProductsCardList();
    forceRender((prev) => !prev);
  }

  const handleSearchChange = (event) => {
    searchVal=event.target.value;
    // const sortedData = productsData.filter((product)=>product.productname.toLowerCase().includes(searchVal.toLowerCase()));
    // setProductsData(sortedData);
    // if (searchVal === "") { setProductsData(fullProductsData); }
    // const filterBySearch = productsData.filter((item) => {
    //     if (item.toLowerCase()
    //         .includes(searchVal.toLowerCase())) { return item; }
    // })
    // setProductsData(filterBySearch);
    // forceRender((prev) => !prev);

  }

  const merge = (a, b, predicate = (a, b) => a === b) => {
    const c = [...a]; // copy to avoid side effects
    // add all items from B to copy C if they're not already present
    b.forEach((bItem) => (c.some((cItem) => predicate(bItem, cItem)) ? null : c.push(bItem)))
    return c;
}

// merge(['a', 'b', 'c'], ['c', 'x', 'd']);
// // => ['a', 'b', 'c', 'x', 'd']

// merge([{id: 1}, {id: 2}], [{id: 2}, {id: 3}], (a, b) => a.id === b.id);
// // [{id: 1}, {id: 2}, {id: 3}]

  function handleSearchClick() {
    if (searchVal === "") { setProductsData(fullProductsData); return;}
    const [min, max] = filteredPrice;
    const country = filteredCountry;
    let bigarray = [];
    let splitedSearchVal = [];
    if (country != "GLOBAL") {
      splitedSearchVal = searchVal.toLowerCase().replace(/[^a-zA-Z-0-9 ]/g, "").split(" ");
      bigarray = splitedSearchVal.map( (val) =>
      fullProductsData.filter((product)=> product.productname.toLowerCase().includes(val)&&product.retailprice>=min && product.retailprice<=max && product.country.includes(country)));
    }else{
      splitedSearchVal = searchVal.toLowerCase().replace(/[^a-zA-Z-0-9 ]/g, "").split(" ");
      bigarray = splitedSearchVal.map( (val) =>
      fullProductsData.filter((product)=> product.productname.toLowerCase().includes(val)&&product.retailprice>=min && product.retailprice<=max ));
    }
    
    if (splitedSearchVal.length == 1){const [w] = bigarray;setProductsData(w);}
    if (splitedSearchVal.length >= 2){
      const [y, z] = bigarray;
      var count = 0;
      var finalarray = [];
      while (bigarray.length > 0) {
        if (count == 0){
          const [y, z] = bigarray;
          const mergedfirsttwo = merge(y, z, (a, b) => a.id === b.id);
          finalarray = mergedfirsttwo;
          const chunk = bigarray.splice(0,2);
        }
        if(count >= 1){
          const [x] = bigarray;
          const keepmerging = merge(finalarray, x, (a, b) => a.id === b.id);
          finalarray = keepmerging;
          const chunk = bigarray.splice(0,1);
        }
        count+=1;
        }
        setProductsData(finalarray);
      }
    
    
    }

    function CheckoutMobile () {
      return (<>{prevCart != [] && prevCart != null ? <Stack spacing={2} sx={{ overflow: 'auto' }}>
      <Card orientation="horizontal" variant="outlined" sx={{ borderRadius:0 }}>
      {/* <CardOverflow
        variant="soft"
        color="primary"
        sx={{
          px: 0.2,
          writingMode: 'vertical-rl',
          justifyContent: 'center',
          fontSize: 'xs',
          fontWeight: 'xl',
          letterSpacing: '1px',
          textTransform: 'uppercase',
          borderRight: '1px solid',
          borderColor: 'divider',
        }}
      >
        Checkout
      </CardOverflow> */}
      <Grid
  container
  direction="column"
  justifyContent="flex-start"
  alignItems="flex-start"
  gap={2}
>
<Typography fontWeight="md">
          Checkout Items (Total: RM{totalprice.toFixed(2)}) 
        </Typography>
        <Grid
  container
  direction="horizontal"
  justifyContent="flex-start"
  alignItems="flex-start"
  gap={2}
>
        <Button fontWeight="md" color="success" onClick={handlePaymentButtonClick}>
          Pay Now
        </Button>
        <Button fontWeight="md" variant="outlined" color="danger" onClick={handleDeleteCartItem}>
        <DeleteRoundedIcon/> Reset Cart
        </Button>
        </Grid>
{prevCart.map ( (product) => <Card orientation="vertical" variant="plain">
      <CardOverflow>
        <AspectRatio ratio="1" sx={{ width: 90 }}>
          <img
            src={product?.productdetails?.productimages}
            srcSet={product?.productdetails?.productimages}
            loading="lazy"
            alt=""
          />
        </AspectRatio>
      </CardOverflow>
      <CardContent>
        <Typography fontWeight="md" textColor="success.plainColor">
          {product?.productdetails?.productname} {product?.ordertype == "preorder" ? " (Pre-order)":""}
        </Typography>
        <Typography level="body-sm">RM{parseFloat(product?.productdetails?.retailprice).toFixed(2)} x {product?.quantity} = <b>RM{(parseFloat(product?.totalprice)).toFixed(2)}</b></Typography>
      </CardContent>
      </Card>)}
      </Grid>
    </Card>
    </Stack>:<></>}</>);
    }

    function CheckoutLaptop () {
      return (<>{prevCart != [] && prevCart != null ? <Stack spacing={2} sx={{ overflow: 'auto' }}>
      <Card orientation="horizontal" variant="outlined" sx={{ width: 480, borderRadius:0 }}>
      {/* <CardOverflow
        variant="soft"
        color="primary"
        sx={{
          px: 0.2,
          writingMode: 'vertical-rl',
          justifyContent: 'center',
          fontSize: 'xs',
          fontWeight: 'xl',
          letterSpacing: '1px',
          textTransform: 'uppercase',
          borderRight: '1px solid',
          borderColor: 'divider',
        }}
      >
        Checkout
      </CardOverflow> */}
      <Grid
  container
  direction="column"
  justifyContent="flex-start"
  alignItems="flex-start"
  gap={2}
>
<Typography fontWeight="md">
          Checkout Items (Total Price: RM{totalprice.toFixed(2)})
        </Typography>
        <Grid
  container
  direction="horizontal"
  justifyContent="flex-start"
  alignItems="flex-start"
  gap={2}
>
        <Button fontWeight="md" color="success" onClick={handlePaymentButtonClick}>
          Make Payment Now
        </Button>
        <Button fontWeight="md" variant="outlined" color="danger" onClick={handleDeleteCartItem}>
        <DeleteRoundedIcon/> Reset Cart
        </Button>
        </Grid>
{prevCart.map ( (product) => <Card orientation="horizontal" variant="plain" sx={{ width: 460 }}>
      <CardOverflow>
        <AspectRatio ratio="1" sx={{ width: 90 }}>
          <img
            src={product?.productdetails?.productimages}
            srcSet={product?.productdetails?.productimages}
            loading="lazy"
            alt=""
          />
        </AspectRatio>
      </CardOverflow>
      <CardContent>
        <Typography fontWeight="md" textColor="success.plainColor">
          {product?.productdetails?.productname} {product?.ordertype == "preorder" ? " (Pre-order)":""}
        </Typography>
        <Typography level="body-sm">RM{parseFloat(product?.productdetails?.retailprice).toFixed(2)} x {product?.quantity} = <b>RM{(parseFloat(product?.totalprice)).toFixed(2)}</b></Typography>
      </CardContent>
      </Card>)}
      </Grid>
    </Card>
    </Stack>:<></>}</>);
    }


  // function ProductsCardList() {
  //   return (<>{ productsData ? productsData?.map(product => (
  //     <RentalCard
  //     key={product?.id}
  //     title={product?.productname}
  //     preorder={product?.preorder}
  //     image={product?.productimages}
  //     country={product?.country}
  //     id={product?.id}
  //     retailprice={product?.retailprice}
  //     variantcompareprice={product?.variantcompareprice}
  //     currentstock={product?.currentstock}
  //     samplestock={product?.samplestock}
  //     onClick={handleCardClick}
  //   />
  //   )):<></>}</>  );
  // }

  const TextInput = () => {
    return (
        <Input
        placeholder="Search"
        // value={'Melbourne'}
        startDecorator={<SearchRoundedIcon />}
        aria-label="Search"
        onChange={handleSearchChange}
      />
    );
  }

  const PaymentModal = () =>{
    return (
      <Modal open={paymentModalOpen} 
      style = {{
        overflow:'scroll'
      }}
      onClose={()=>setPaymentModalOpen(false)}>
        <ModalDialog>
        {selectedRow?.b2border ?  <DialogTitle> B2B Invoice Details</DialogTitle> : <DialogTitle> Invoice Details</DialogTitle>}
          <DialogContent><Typography level="body-sm">Total Amount: RM{parseFloat(selectedRow?.totalprice).toFixed(2)}</Typography>
          <Typography level="body-sm">Final Amount: RM{parseFloat(selectedRow?.finalprice).toFixed(2)}</Typography>
          <Typography level="body-sm">Discount Type: {selectedRow?.discounttype == "lifetime" ? "Lifetime member 5% discount on order" : "No discount applied"}</Typography>
          {selectedRow?.b2border ? <Typography level="body-sm">Import insurance: RM{selectedRow?.insurancetype=="1percent"? (selectedRow?.finalprice * 0.01).toFixed(2) : parseFloat(55.00)}</Typography> : <></>}
          {selectedRow?.b2border ? <Typography level="body-sm">Total duty & tax: RM{calculateTotalDuty(selectedRow)}</Typography> : <></>}
          {selectedRow?.b2border ? <Typography level="body-sm">Total delivery cost: RM{calculateTotalDelivery(selectedRow)}</Typography> : <></>}
          <Typography level="body-sm">Remarks: {selectedRow?.remarks != "" ? selectedRow?.remarks : "No remarks given"}</Typography>
          {selectedRow?.salesperson ? <><Typography level="body-sm">Contributing Salespeople: </Typography> <Stack spacing={1} direction="row" sx={{ mb: 2 }}>{selectedRow?.salesperson?.map((sales) => <Chip color="success">{sales?.firstname + " " + sales?.lastname}</Chip>)}</Stack></> : <Typography level="body-sm">Contributing salesperson: None</Typography>}
          <Stack spacing={2} sx={{ overflow: 'auto' }}>

          {role=="superadmin" && selectedRow?.payoutstatus ? selectedRow?.payoutstatus == "pending" ?
          <form
          onSubmit={(event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const selectedSales = JSON.parse(formJson.sales);
            update_sales(selectedRow?.id,selectedSales);
            setPaymentModalOpen(false);
          }}
        ><Stack direction="row" spacing={1}>
            <Select variant="plain" multiple placeholder="Select a sales"
          name="sales">
      {salespeople.map((salesperson) => <Option value={salesperson?.id} id={salesperson?.id}>{salesperson?.firstname + " " + salesperson?.lastname + " (" + salesperson?.role + ")"}</Option>)}
            </Select>
            <Button type="submit" variant="plain">Update Sales</Button>
          </Stack></form> : <Button disabled variant="plain">Paid to Sales</Button> : <></>}
          {selectedRow?.product?.map ( (product, index) => 
          <Card orientation="horizontal" variant="plain">
        <AspectRatio ratio="1" sx={{ width: 90 }}>
          <img
            src={product?.productdetails?.productimages}
            srcSet={product?.productdetails?.productimages}
            loading="lazy"
            alt=""
          />
        </AspectRatio>
      <CardContent>
        <Typography fontWeight="md" textColor="success.plainColor">
        Item {index+1}: {product?.productdetails?.id} {product?.productdetails?.productname} {product?.ordertype == "preorder" ? " (Pre-order)":""}
        </Typography>
        <Typography level="body-sm">RM{selectedRow?.b2border ? (parseFloat(product?.productdetails?.variantcompareprice)*5).toFixed(2) : parseFloat(product?.productdetails?.retailprice).toFixed(2)} x {product?.quantity} = <b>RM{(parseFloat(product?.totalprice)).toFixed(2)}</b></Typography>
        { selectedRow?.b2border ? <Typography level="body-sm"> Duty & Custom Tax: RM{(parseFloat(product?.dutytax*product?.productdetails?.variantcompareprice*5)).toFixed(2)} x {product?.quantity} = <b>RM{(parseFloat(product?.dutytax*product?.productdetails?.variantcompareprice*5*product?.quantity)).toFixed(2)}</b></Typography> : <></>}
        { selectedRow?.b2border ? <Typography level="body-sm"> Delivery Cost: RM{(parseFloat(product?.deliverycost)).toFixed(2)} x {product?.quantity} = <b>RM{(parseFloat(product?.deliverycost*product?.quantity)).toFixed(2)}</b></Typography> : <></>}
        { selectedRow?.b2border && selectedRow?.insurancetype == "1percent"? <Typography level="body-sm"> Import Insurance: RM{(parseFloat(product?.productdetails?.variantcompareprice*5*0.01)).toFixed(2)} x {product?.quantity} = <b>RM{(parseFloat(product?.productdetails?.variantcompareprice*5*product?.quantity*0.01)).toFixed(2)}</b></Typography> : <></>}
        {selectedRow?.b2border ? (product?.deliverystatus=="pending" ? <Button onClick={()=>handleStockUpdate(selectedRow,product)} variant="plain">Add to Stock</Button> : <Button disabled variant="plain">Delivered</Button>) : <></>}
        {!selectedRow?.b2border && role=="superadmin" ? ( product?.deliverystatus ? product?.deliverystatus=="pending" ? 
        <Stack direction="row" spacing={1}><Select variant="plain" onChange={handleOnDistributorChange} placeholder="Select a distributor">
      {distributors.map((distributor) => <Option value={distributor?.id} id={distributor?.id}>{distributor?.firstname + " " + distributor?.lastname + " (" + distributor?.role + ")"}</Option>)}
    </Select>
    <Button onClick={()=>handleStockDeduct(selectedRow,product)} variant="plain">Deduct Stock</Button></Stack> : <Button disabled variant="plain">Distributed by {product?.distributor?.map((distributor)=> distributor?.firstname + " " + distributor?.lastname)}</Button>: <></>) : <></>}
      </CardContent>
      </Card>)}</Stack>
      </DialogContent>
          <ModalClose />
        </ModalDialog>
      </Modal>
    );
  }

  function Search(){
    return (<div>
      <Stack spacing={1} direction="row" sx={{ mb: 2 }}>
        <FormControl sx={{ flex: 1 }} >
          <TextInput/>
        </FormControl>
        <Button variant="solid" color="primary" id="searchButton" onClick={handleSearchClick}>
          Search
        </Button>
      </Stack>
      <Typography level="body-sm">{productsData.length} products in total</Typography>
    </div>);
  }

  useEffect(() => {
    read_all_invoices();
    get_all_distributors();
    get_all_sales();
  },[])

  if (loading) {
    return (<CssVarsProvider disableTransitionOnChange>
        <CssBaseline />
        <NavBar accessToken={accessToken} username={username} email={email} role={role}/>
        <Box
          component="main"
          sx={{
            height: 'calc(100vh - 55px)', // 55px is the height of the NavBar
            display: 'grid',
            gridTemplateColumns: { xs: 'auto', md: '60% 40%' },
            gridTemplateRows: 'auto 1fr auto',
          }}
        >
          <LinearProgress/>
          <Box
            sx={{
              gridRow: 'span 3',
              display: { xs: 'none', md: 'flex' },
              backgroundColor: 'background.level1',
              backgroundSize: 'cover',
              backgroundImage:
                'url("https://www.shenyuncollections.com/cdn/shop/files/ABOUT-US-SY.jpg?v=1675921248&width=3200")',
            }}
          />
        </Box>
      </CssVarsProvider>
    );
    } else if (!loading && accessToken && !error && (role == "superadmin" || role == "admin" || role=="sales" || role=="distributor")){

  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <NavBar accessToken={accessToken} username={username} email={email} role={role}/>
      <Box
        component="main"
        sx={{
          height: 'calc(100vh - 55px)', // 55px is the height of the NavBar
          display: 'grid',
          gridTemplateColumns: { xs: 'auto', md: '100%' },
          gridTemplateRows: 'auto 1fr auto',
        }}
      >
        <Stack
          sx={{
            backgroundColor: 'background.surface',
            px: { xs: 2, md: 4 },
            py: 2,
            borderBottom: '1px solid',
            borderColor: 'divider',
          }}
        >
          <PaymentModal/>
          <Box
            sx={{
              display: 'flex',
              mb: 1,
              gap: 1,
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'start', sm: 'center' },
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <Typography level="h2" component="h1">
              Invoices
            </Typography>
          </Box>
          <InvoiceTable invoiceData={invoicesData} selectedRow={handleSelectedRow}/>
          <InvoiceList invoiceData={invoicesData} selectedRow={handleSelectedRow}/>
          </Stack>
        {/* <Pagination /> */}
      </Box>
    </CssVarsProvider>
  );
}else{
  return(
    <CssVarsProvider disableTransitionOnChange>
        <CssBaseline />
        <NavBar accessToken={accessToken} username={username} email={email} role={role}/>
        <Box
          component="main"
          sx={{
            height: 'calc(100vh - 55px)', // 55px is the height of the NavBar
            display: 'grid',
            gridTemplateColumns: { xs: 'auto', md: '60% 40%' },
            gridTemplateRows: 'auto 1fr auto',
          }}
        >
          <Stack
            sx={{
              backgroundColor: 'background.surface',
              px: { xs: 2, md: 4 },
              py: 2,
              borderBottom: '1px solid',
              borderColor: 'divider',
            }}
          >
            <Typography>You are not authorised to view this page. Kindly sign in first.</Typography>
          </Stack>
          <Box
            sx={{
              gridRow: 'span 3',
              display: { xs: 'none', md: 'flex' },
              backgroundColor: 'background.level1',
              backgroundSize: 'cover',
              backgroundImage:
                'url("https://www.shenyuncollections.com/cdn/shop/files/ABOUT-US-SY.jpg?v=1675921248&width=3200")',
            }}
          />
        </Box>
      </CssVarsProvider>
  );
}

}